import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import logo from 'assets/img/logo_transparent_medium.png';

const SideNavigationWrapper = styled.div`
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.15);
  background-color: #f8f9fa;
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  z-index: 1000;
  overflow-x: hidden;
  padding-top: 20px;
`;

const MobileNavigationWrapper = styled.div`
    @media (max-width: 1024px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: ${(props) => (props.isOpen ? "100vh" : "4rem")};
        background-color: #f8f9fa;
        box-shadow: ${(props) =>
                props.isOpen ? "0px 4px 10px rgba(0, 0, 0, 0.15)" : "none"};
        transition: height 0.3s ease;
        z-index: 1000;
    }
`;

const MenuIcon = styled.div`
    color: #08848f; 
    cursor: pointer;
    font-size: 1.5rem;
    margin-right: 1rem;
`;

const NavigationLink = styled.div`
  &.active {
    background: #08848f;
    
    p, i {
      color: white;
    }
  }

  cursor: pointer;
  box-sizing: border-box;
  border: 3px solid transparent;
  padding: 5px;
  border-radius: 5px;

  p {
    margin: 5px;
    font-size: 13px;
    color: #434342;
    max-width: 150px;
  }

  i {
    min-width: 30px;
    font-size: 12px;
    color: #434342;
    text-align: center;
  }

  &:hover:not(.active) {
    p, i {
      color: black;
    }
  }
`;

const Logo = styled.div`
  text-align: center;
   img {
    width: 90%;
   }
`;

const FooterContent = styled.div`
  border-top: 1px solid #e9ecef;
  padding: 10px;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const isNavItemActive = (path) => window.location.pathname.includes(path);

export const SideNavigation = (props) => {
  const {
    routes,
    logout,
    isMobile,
    isMenuOpen,
    toggleMenu,
  } = props;

  const loggedIn = useSelector(state => state.authentication.loggedIn);
  const [sidebarRoutes, setSidebarRoutes] = useState([]);
  const [username, setUsername] = useState('');
  const [expandedSections, setExpandedSections] = useState({});

  const handleLogout = useCallback(() => {
    logout();
    window.location.href = window.BASE_URL;
  }, [logout]);

  useEffect(() => {
    if (loggedIn === false) {
      return handleLogout();
    }

    const user = window.localStorage.getItem('login');
    setUsername(user);
  }, [loggedIn, handleLogout]);

  useEffect(() => {
    const userRole = window.localStorage.getItem('role');
    const sidebarRoutes = routes
      .filter((route) => route.visible === true)
      .filter((route) => route.role.includes(userRole) === true);

    setSidebarRoutes(sidebarRoutes);
  }, [routes]);


  const renderNavigationLinkItemContent = (route, index) => {
    const navItemIsActive = isNavItemActive(route.layout + route.path);
    const icon = `fa fa-${route.icon}`;

    return (
      <NavigationLink key={route.name} className={navItemIsActive ? 'active' : ''}
                      onClick={() => {
                          if (isMobile) {
                              toggleMenu();
                          }
                      }}>
          <div className="flex items-center">
              <i className={icon}></i>
              <Link className='ml-2' to={route.layout + route.path}>
                  <p className="inline-block">{route.name}</p>
              </Link>
          </div>
      </NavigationLink>
    );
  };

  const renderSectionHeader = (sectionName, toggleSection, isExpanded) => {
    const iconClass = isExpanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down';

    return (
      <div key={sectionName}
         className="nav-header flex justify-between items-center cursor-pointer px-4 py-2 bg-gray-100 hover:bg-gray-200"
         onClick={() => toggleSection(sectionName)}>
        <p className="m-0">{sectionName}</p>
        <i className={`${iconClass} ml-2`}></i>
      </div>
    );
  };

  const renderRouteLinks = () => {
    const toggleSection = (sectionName) => {
      setExpandedSections((prevState) => ({
        ...prevState,
        [sectionName]: !prevState[sectionName],
      }));
    };

    const groupedRoutes = {};
    const ungroupedRoutes = [];

    sidebarRoutes.forEach((route) => {
      if (route.sectionHeader) {
        if (!groupedRoutes[route.sectionHeader]) {
          groupedRoutes[route.sectionHeader] = [];
        }
        groupedRoutes[route.sectionHeader].push(route);
      } else {
        ungroupedRoutes.push(route);
      }
    });

    return (
      <div>
        {ungroupedRoutes.map((route, index) => renderNavigationLinkItemContent(route, index))}

        {Object.keys(groupedRoutes).map((sectionName) => {
          const isExpanded = !!expandedSections[sectionName];

          return (
            <div key={sectionName}>
              {renderSectionHeader(sectionName, toggleSection, isExpanded)}
              {isExpanded && (
                <div className="nav-links pl-2">
                  {groupedRoutes[sectionName].map((route, index) =>
                    renderNavigationLinkItemContent(route, index)
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {/* <= 1024px */}
      {!isMobile && (
          <SideNavigationWrapper>
              <Logo>
                  <img src={logo} alt="Logo" className="w-32 mx-auto"/>
              </Logo>
                  <div style={{ padding: 10, marginTop: 20 }}>
                      {renderRouteLinks()}
                  </div>
                  <FooterContent>
                    <div>
                      <p className='text-center'>{username}</p>
                      <button className='text-center underline' onClick={handleLogout} style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer', textDecoration: 'underline', color: 'inherit' }}>Logout</button >
                    </div>
                  </FooterContent>
          </SideNavigationWrapper>
      )}

      {/* > 1024px */}
      {isMobile && (
          <MobileNavigationWrapper isOpen={isMenuOpen}>
            <div className="flex justify-between items-center p-3">
              <img src={logo} alt="Logo" className="w-20 mx-auto"/>
                    <MenuIcon onClick={toggleMenu}>
                        <i className={`fa ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
                    </MenuIcon>
                </div>
                {isMenuOpen && (
                    <>
                        <div style={{padding: 10, marginTop: 20}}>
                            {renderRouteLinks()}
                        </div>
                        <FooterContent>
                            <div>
                                <p className='text-center'>{username}</p>
                                <button className='text-center underline' onClick={handleLogout} style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer', textDecoration: 'underline', color: 'inherit' }}>Logout</button >
                            </div>
                        </FooterContent>
                    </>
                )}
            </MobileNavigationWrapper>
        )}
    </>
  )
};
