
import React from 'react';
import styled from 'styled-components';
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";

import PanelHeader from '../PanelHeader/PanelHeader';

const Title = styled(CardTitle)`
  font-family: 'Brother1816', sans-serif;
  font-weight: bold;
`;

export const PageWrapper = ({ children, title, subtitle, withPatientBanner = false, ActionButton }) => {

  return (
    // style={{ marginTop: withPatientBanner ? '8em' : '' }}
    <div className={ withPatientBanner ? 'md:mt-48 lg:mt-32' : ''}>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="flex justify-between items-center">
                <div>
                  <Title className="text-3xl mb-2 mt-2 font-medium" tag="h4">{title}</Title>
                  {subtitle && <p className="text-sm">{subtitle}</p>}
                </div>
                {ActionButton && <ActionButton className="ml-4" />}
              </CardHeader>
              <CardBody>{children}</CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
};
